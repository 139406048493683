import React from "react"
import { MdOpenInNew } from "react-icons/md"
import Layout from "../components/layout"
import SEO from "../components/seo"

const tools = [
	{
		name: "LupusMinder",
		description:
			"An app targeted specifically at lupus patients. Allows tracking and management of symptoms, doctor appointments and more.",
		link: "https://www.hss.edu/lupusminder.asp",
	},
	{
		name: "Flaredown",
		description:
			"A symptom tracking application allowing you to monitor them. A good way to try and detect triggers that cause flare ups in your symptoms.",
		link: "http://flaredown.com/",
	},
	{
		name: "Medisafe",
		description:
			"Medication management app allowing health practitioners to collaborate with their patients in patient care. Particularly useful for complex conditions such as lupus.",
		link:
			"https://get.medisafe.com/download/?_branch_match_id=784596922791041937",
	},
	{
		name: "Lupie Diary",
		description:
			"An app that allows you to schedule reminders for your medications, manage medical appointments and keep track of current prescriptions and symptoms.",
		link: "https://www.lupusuk.org.uk/lupus-smartphone-app/",
	},
	{
		name: "Plain Talk About Lupus and Key Words",
		description:
			"This PDF handbook, put out by the Harvard School of Public Health among others, uses plain, non-jargon terms to explain the words that you are likely to come across if diagnosed with lupus.",
		link:
			"https://cdn1.sph.harvard.edu/wp-content/uploads/sites/135/2012/09/lupusglossary.pdf",
	},
]

const Android = () => {
	return (
		<Layout>
			<SEO title="Helpful tools to manage your lupus" />
			<h1>Tools that may help you manage your lupus</h1>
			{tools.map(({ name, description, link }) => (
				<a
					key={name}
					href={link}
					target="_blank"
					rel="noopener noreferrer"
					className="block flex rounded shadow p-4 my-8 text-purple-900 bg-purple-200 focus:outline-none focus:shadow-outline hover:bg-purple-300 font-bold"
				>
					<MdOpenInNew className="mr-6 flex-grow-0 flex-shrink-0 my-auto" />
					<div className="flex-grow leading-snug">
						<p className="font-semibold m-0 mb-2 text-purple-900">{name}</p>
						<p className="m-0 mb-2 font-normal text-purple-800">
							{description}
						</p>
						<p className="m-0 font-medium text-sm text-purple-800 underline break-word">
							{link}
						</p>
					</div>
				</a>
			))}
		</Layout>
	)
}

export default Android
